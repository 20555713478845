import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api/api";

export const getQuestionTypes = createAsyncThunk(
  "questions/getQuestionTypes",
  async (object, { getState, rejectWithValue }) => {
    try {
      const { data } = await API.get("/dashboard/question/types");
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getQuestionStyles = createAsyncThunk(
  "questions/getQuestionStyles",
  async (questionTypeId, { getState, rejectWithValue }) => {
    try {
      const data = await API.get(`/dashboard/question/style/${questionTypeId}`);
      return data.data.payload;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getConfigAttributes = createAsyncThunk(
  "questions/getConfigAttributes",
  async (questionStyleId, { getState, rejectWithValue }) => {
    try {
      const data = await API.get(`/dashboard/attribute/question/style/${questionStyleId}`);
      return JSON.parse(data.data.payload.data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createNewQuestion = createAsyncThunk(
  "questions/createNewQuestion",
  async (object, { getState, rejectWithValue }) => {
    try {
      const data = await API.post("/dashboard/question", object);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateQuestion = createAsyncThunk(
  "questions/updateQuestion",
  async (object, { getState, rejectWithValue }) => {
    try {
      const data = await API.post(`/dashboard/question/${object.questionId}`, object.data);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const slice = createSlice({
  name: "questions",
  initialState: {
    questionTypes: {
      data: [],
      status: null,
      error: null,
    },
    questionStyles: {
      data: [],
      status: null,
      error: null,
    },
    configAttributes: [],
    status: null,
    error: null,
  },
  reducers: {
    clearQuestionsSuccess: (state, action) => {
      state.configAttributes = [];
    },
  },
  extraReducers: {
    [getQuestionTypes.pending]: (state, action) => {
      state.questionTypes.status = "loading";
    },
    [getQuestionTypes.fulfilled]: (state, action) => {
      state.questionTypes.status = "success";
      state.questionTypes.data = action.payload.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    [getQuestionTypes.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.questionTypes.error = payload;
    },
    [getQuestionStyles.pending]: (state, action) => {
      state.questionStyles.status = "loading";
    },
    [getQuestionStyles.fulfilled]: (state, action) => {
      state.questionStyles.status = "success";
      state.questionStyles.data = action.payload.sort((a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      });
    },
    [getQuestionStyles.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.questionStyles.error = payload;
    },

    [getConfigAttributes.pending]: (state, action) => {
      state.status = "loading";
    },
    [getConfigAttributes.fulfilled]: (state, action) => {
      state.status = "success";
      state.configAttributes = action.payload;
    },
    [getConfigAttributes.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
  },
});
export default slice.reducer;

const { clearQuestionsSuccess } = slice.actions;

export const clearQuestions = () => (dispatch) => {
  try {
    dispatch(clearQuestionsSuccess());
  } catch (error) {
    return console.log(error.message);
  }
};
